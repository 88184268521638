import React, {useEffect} from 'react';
import styles from './indexStyle.module.scss'
import {ReactComponent as LogoIcon} from "../../../assets/icons/vncLogoLogin.svg";
import {useHistory} from "react-router-dom";
import {HOME, INTEGRATIONS, LOGIN} from "../../../routesConstants";
import {useSelector} from "react-redux";
import {makeSelectUser} from "../../../store/auth/selectors";
import profileImage from "../../../assets/images/user.png";
import DefaultButton from "../../../components/Shared/Buttons/DefaultButton";
import {COLORS} from "../../../constants/colors";
import {helperService} from "../../../services/HelperService";

export function ZapierAuthPage() {
    const history = useHistory();
    const queryParams = new URLSearchParams(history.location.search);
    const clientId = queryParams.get('client_id') ? queryParams.get('client_id') : localStorage.getItem('zapierClientId');
    const state = queryParams.get('state') ? queryParams.get('state') : localStorage.getItem('zapierState');
    const user = useSelector(makeSelectUser());

    const onPressButton = async () => {
        try {
            const data = {
                clientId: clientId,
                state: state,
            }
            const res = await helperService.zapierAllowAuth(data);
            localStorage.removeItem('zapierRequest');
            localStorage.removeItem('zapierClientId');
            localStorage.removeItem('zapierState');
            window.location.href = res;
            // history.push(HOME);
        } catch (err) {
            console.log(err)
        }
    }

    const onPressCancel = async () => {
        await localStorage.removeItem('zapierRequest');
        await localStorage.removeItem('zapierClientId');
        await localStorage.removeItem('zapierState');
        history.push(HOME);
    }

    useEffect(() => {
        if (!user) {
            localStorage.setItem('zapierRequest', 'active');
            localStorage.setItem('zapierClientId', clientId);
            localStorage.setItem('zapierState', state);
            history.push(LOGIN);
        }
    }, [history, user])

    return (
            <div className={styles.wrapper}>
                <div className={styles.window}>
                    <div className={styles.logoWrapper}>
                        <LogoIcon width={32} height={32}/>
                        <p className={styles.titleText}>StratifyPro</p>
                    </div>
                    <p className={styles.confirmationText}>Zapier wants to access your StratifyPro account data</p>
                    <div className={styles.userInfoWrapper}>
                        <img src={user?.photo ? user?.photo : profileImage} className={styles.profileImg}
                             alt={'user'}/>
                        <p>{user?.email}</p>
                    </div>
                    <p className={styles.subConfirmationText}>{'Please click below to continue your survey'}</p>
                    <div className={styles.bottomButtons}>
                        <DefaultButton width={'45%'} type={'button'} onClick={onPressCancel} height={45}
                                       fontWeight={600} title={'Cancel'} border={`1px solid ${COLORS.blue}`}
                                       backgroundColor={COLORS.white} fontColor={COLORS.legendBlack}/>
                        <DefaultButton width={'45%'} onClick={onPressButton} height={45} fontWeight={600}
                                       title={'Continue'} buttonStyles={{marginLeft: '8px'}}/>
                    </div>
                </div>
            </div>
    );
}

export default ZapierAuthPage;
