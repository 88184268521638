import httpService from "./HttpService";
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
    GET_FOUNDATION: '/foundations',
    GET_ORGANIZATION_ACTIVITY: '/audit-logs/organization/:page/:perPage/',
    GET_LOG_TYPE_ACTIVITY: 'audit-logs/:logType/:page/:perPage/:sourceId?/',
    GET_USER_ACTIVITY: 'audit-logs/user/:userId/:logType/:page/:perPage/:sourceId?/',
    GET_USERS_BY_ORGANIZATION: '/organizations/users',
    GET_THEMES: './themes',
    CREATE_THEME: './themes',
    UPDATE_THEME: './themes/:id',
    DELETE_THEME: './themes/:id',
    GET_FRAMEWORKS: './frameworks',
    GET_FRAMEWORK_BY_ID: '/frameworks/:id',
    CREATE_COMMENT: '/comments',
    GET_COMMENTS: '/comments/:entityType/:id',
    CREATE_UPDATE_FOUNDATION: 'foundations',
    CREATE_INTEGRATION: '/integration-requests',
    UPDATE_FRAMEWORK: 'frameworks',
    TRAINING_REQUEST: '/training/training-request',
    ZAPIER_ALLOW_AUTH: '/zapier/allow-auth',
}

class HelperService {
    constructor(httpService) {
        this.httpService = httpService;
    }

    getFoundation = () => {
        return this.httpService.request({
            url: ROUTES.GET_FOUNDATION,
            method: HTTP_METHODS.GET,
        });
    };

    getOrganizationActivity = (page, perPage) => {
        return this.httpService.request({
            url: ROUTES.GET_ORGANIZATION_ACTIVITY.replace(':page', page).replace(':perPage', perPage),
            method: HTTP_METHODS.GET,
        });
    };

    getLogTypeActivity = (logType, page, perPage, sourceId) => {
        return this.httpService.request({
            url: ROUTES.GET_LOG_TYPE_ACTIVITY.replace(':logType', logType)
                .replace(':page', page)
                .replace(':perPage', perPage)
                .replace(':sourceId', sourceId),
            method: HTTP_METHODS.GET,
        });
    };

    getUserActivity = (userId, logType, page, perPage, sourceId) => {
        return this.httpService.request({
            url: ROUTES.GET_USER_ACTIVITY.replace(':userId', userId)
                .replace(':logType', logType)
                .replace(':page', page)
                .replace(':perPage', perPage)
                .replace(':sourceId', sourceId),
            method: HTTP_METHODS.GET,
        });
    };

    getUsersByOrganization = () => {
        return this.httpService.request({
            url: ROUTES.GET_USERS_BY_ORGANIZATION,
            method: HTTP_METHODS.GET,
        });
    };

    getThemes = () => {
        return this.httpService.request({
            url: ROUTES.GET_THEMES,
            method: HTTP_METHODS.GET,
        });
    };

    deleteTheme = (themeId) => {
        return this.httpService.request({
           url: ROUTES.DELETE_THEME.replace(':id', themeId),
           method: HTTP_METHODS.DELETE,
        });
    }

    createTheme = (data) => {
        return this.httpService.request({
            url: ROUTES.CREATE_THEME,
            method: HTTP_METHODS.POST,
            data
        });
    }

    createIntegration = (data) => {
        return this.httpService.request({
            url: ROUTES.CREATE_INTEGRATION,
            method: HTTP_METHODS.POST,
            data
        });
    }

    sendTrainingRequestForm = (data) => {
        return this.httpService.request({
            url: ROUTES.TRAINING_REQUEST,
            method: HTTP_METHODS.POST,
            data
        });
    }

    updateTheme = (data, themeId) => {
        return this.httpService.request({
            url: ROUTES.UPDATE_THEME.replace(':id', themeId),
            method: HTTP_METHODS.PUT,
            data
        });
    }

    getFrameworks = () => {
        return this.httpService.request({
            url: ROUTES.GET_FRAMEWORKS,
            method: HTTP_METHODS.GET,
        });
    };

    getFrameworkDetails = (frameworkId) => {
        return this.httpService.request({
            url: ROUTES.GET_FRAMEWORK_BY_ID.replace(':id', frameworkId),
            method: HTTP_METHODS.GET,
        });
    };

    createComment = (data) => {
        return this.httpService.request({
            url: ROUTES.CREATE_COMMENT,
            method: HTTP_METHODS.POST,
            data
        });
    };

    getComments = (entityType, entityId) => {
        return this.httpService.request({
            url: ROUTES.GET_COMMENTS.replace(':entityType', entityType).replace(':id', entityId),
            method: HTTP_METHODS.GET,
        });
    };
    createUpdateFoundation = (data) => {
        return this.httpService.request({
            url: ROUTES.CREATE_UPDATE_FOUNDATION,
            method: HTTP_METHODS.POST,
            data,
        });
    };

    updateFramework = (data) => {
        return this.httpService.request({
            url: ROUTES.UPDATE_FRAMEWORK,
            method: HTTP_METHODS.POST,
            data,
        });
    };

    zapierAllowAuth = (data) => {
        return this.httpService.request({
            url: ROUTES.ZAPIER_ALLOW_AUTH,
            method: HTTP_METHODS.POST,
            data,
        });
    };
}

export const helperService = new HelperService(httpService);
