import React, {useCallback, useEffect, useState} from 'react';
import styles from './ExcelIntegration.module.scss'
import OutlinedSelectInput from "../../Inputs/OutlinedSelectInput";
import metricsService from "../../../../services/MetricsService";
import {getAlphabet12} from "../../../../utils/format";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {FormControl} from "@mui/material";
import DefineYourTarget from "../DefineYourTargets";
import {useDispatch, useSelector} from "react-redux";
import {makeSelectUser} from "../../../../store/auth/selectors";
import CustomModal from "../../Modals/CustomModal";
import {warningModalSelector} from "../../../../store/helper/selectors";
import {setWarningModal} from "../../../../store/helper/actions";

const COLUMN_LETTERS = getAlphabet12();

export function ExcelIntegration({item, setFormValues, clearProp, errors}) {
  const dispatch = useDispatch();

  const [connections, setConnections] = useState([]);
  const [files, setFiles] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [formState, setFormState] = useState({});
  const [spreadsheetPermissionError, setSpreadsheetPermissionError] = useState(null);

  const user = useSelector(makeSelectUser());
  const warningModal = useSelector(warningModalSelector());

  const getConnections = async () => {
    try {
      const res = await metricsService.getMicrosoftExcelConnections()
      setConnections(res);
      return res
    } catch (e) {
      console.log('err', {e});
    }
  }

  const onChangeForm = (values) => {
    setFormState(prevState => ({...prevState, ...values}))
  }

  const onSelectConnection = async (val, setState = true, connections) => {
    let connection = connections?.find(item => item.id === val);
    try {
      if (setState) {
        await onChangeForm({
          microsoftConnectionId: val,
          resourceId: '',
          resourceName: '',
          dateIdentifier: '',
          progressIdentifier: '',
          targetIdentifier: '',
        });
      }
      await getFiles(connection?.resourceId, connection?.id);
    } catch (e) {
      console.log('err', {e});
    }
  }
  const onSelectSpreadsheet = async (val) => {
    let connection = connections?.find(item => item.id === formState['microsoftConnectionId']);
    try {
      setSheets([]);
      const sheets = await metricsService.getExcelSheets(val, item?.kpiMicrosoftConnection?.microsoftConnection.id || connection?.id);
      const arr = sheets.map((item, index) => {
        return {id:index, name: item.name};
      })
      setSheets(arr);
      onChangeForm({
        resourceId: val,
        resourceName: '',
        dateIdentifier: '',
        progressIdentifier: '',
        targetIdentifier: '',
      });
      return arr;
    } catch (e) {
      console.log('err', {e});
      if (e.data?.message === 'The caller does not have permission') {
        setSpreadsheetPermissionError(true);
      }
      if (e.response.data?.message === "File does not exist.") {
        return 'noFile'
      }
    }
  }

  const getFiles = async (resourceId, microsoftConnectionId) => {
    try {
      const res = await metricsService.getMicrosoftFiles(resourceId, microsoftConnectionId);
      setFiles(res);
    } catch (e) {
      console.log('err', {e});
    }
  }

  const resourceNameExist = (files) => {
    if (files !== 'noFile') {
      return !!files?.find(file => file.name === item?.kpiMicrosoftConnection?.resourceName)
    }
  }


  const setForEdit = async () => {
    const connections = await getConnections();
    await onSelectConnection(item?.kpiMicrosoftConnection?.microsoftConnectionId, false, connections);
    const files = await onSelectSpreadsheet(item?.kpiMicrosoftConnection?.resourceId);

    let form = {
      microsoftConnectionId: item?.kpiMicrosoftConnection?.microsoftConnectionId,
      dateIdentifier: item?.kpiMicrosoftConnection?.dateIdentifier,
      progressIdentifier: item?.kpiMicrosoftConnection?.progressIdentifier,
      targetIdentifier: item?.kpiMicrosoftConnection?.targetIdentifier,
      cumulative: item?.kpiMicrosoftConnection?.cumulative,
      ...((files && files !== 'noFile') && { resourceId: item?.kpiMicrosoftConnection?.resourceId }),
      ...(resourceNameExist(files) && { resourceName: item?.kpiMicrosoftConnection?.resourceName })
    };

    setFormState(prevState => ({ ...prevState, ...form }));
  }

  const onRadioChange = (val) => {
    onChangeForm({
      cumulative: val === 'true',
    });
  };

  useEffect(() => {
    if (!item?.kpiMicrosoftConnection) {
      onChangeForm({
        cumulative: true,
      });
      getConnections();
    }

    return () => {
      closeWarningModal();
    }
  }, []);



  useEffect(() => {
    if (item?.kpiMicrosoftConnection) {
      setForEdit();
    }
  }, [item])

  useEffect(() => {
    setFormValues(formState);
  }, [formState, setFormValues]);

  const getValue = () => {
    return formState['cumulative'] === true
  }

  const handleChangeResourceName = (e) => {
    onChangeForm({
      resourceName: e.target.value,
      dateIdentifier: '',
      progressIdentifier: '',
      targetIdentifier: '',
    });
  };

  const closeWarningModal = useCallback(() => {
    dispatch(setWarningModal({
      isVisible: false,
      title: '',
      component: null,
    }));
  }, [dispatch]);

  return (
    <div className={styles.manuallyContainer}>
      <div>
        <p className={styles.firstStepSelectLabel}>Select connection<span
          className={styles.errorMsg}>{errors?.microsoftConnectionId && ` ${errors?.microsoftConnectionId}`}</span></p>
        <OutlinedSelectInput height={40} options={connections} value={formState['microsoftConnectionId']}
                             handleChange={(val) => onSelectConnection(val.target.value, true, connections)}/>
        {!user.microsoftIntegration && <span
          className={styles.errorMsg}>{`Please go to Integration section and create connection`}</span>}
        <p className={styles.firstStepSelectLabel}>Select File<span
          className={styles.errorMsg}>{errors?.resourceId && ` ${errors?.resourceId}`}</span></p>
        <OutlinedSelectInput disabled={!formState['microsoftConnectionId']} height={40} options={files} value={formState['resourceId']}
                             handleChange={(val) => onSelectSpreadsheet(val.target.value)}/>
        {spreadsheetPermissionError && <span
          className={styles.errorMsg}>{`Share Permission for this Spreadsheet needs to be changed in One Drive`}</span>}
        <p className={styles.firstStepSelectLabel}>Select Sheet Tab<span
          className={styles.errorMsg}>{errors?.resourceName && ` ${errors?.resourceName}`}</span></p>
        <OutlinedSelectInput disabled={!formState['resourceId']} valueKey={'name'} height={40} options={sheets} value={formState['resourceName']}
                             handleChange={handleChangeResourceName}/>
        <div>
          <p className={styles.firstStepSelectLabel}>Date Column<span
            className={styles.errorMsg}>{errors?.dateIdentifier && ` ${errors?.dateIdentifier}`}</span></p>
          <div className={styles.twoSelects}>
            <OutlinedSelectInput disabled={!formState['resourceName']} valueKey={'name'} height={40} options={COLUMN_LETTERS}
                                 value={formState['dateIdentifier']}
                                 handleChange={(val) => onChangeForm({dateIdentifier: val.target.value})}/>
          </div>
        </div>

        <div>
          <p className={styles.firstStepSelectLabel}>Progress Column<span
            className={styles.errorMsg}>{errors?.progressIdentifier && ` ${errors?.progressIdentifier}`}</span></p>
          <div className={styles.twoSelects}>
            <OutlinedSelectInput disabled={!formState['resourceName']} valueKey={'name'} height={40} options={COLUMN_LETTERS}
                                 value={formState['progressIdentifier']}
                                 handleChange={(val) => onChangeForm({progressIdentifier: val.target.value})}/>
          </div>
        </div>


        <div>
          <p className={styles.firstStepSelectLabel}>Target Column<span
            className={styles.errorMsg}>{errors?.targetIdentifier && ` ${errors?.targetIdentifier}`}</span></p>
          <div className={styles.twoSelects}>
            <OutlinedSelectInput disabled={!formState['resourceName']} valueKey={'name'} height={40} options={COLUMN_LETTERS}
                                 value={formState['targetIdentifier']}
                                 handleChange={(val) => onChangeForm({targetIdentifier: val.target.value})}/>
          </div>
        </div>

        <div>
          <p className={styles.firstStepSelectLabel}>How is the data set up in the spreadsheet?<span
            className={styles.errorMsg}>{errors?.cumulative && ` ${errors?.cumulative}`}</span></p>

          <FormControl>
            <RadioGroup
              value={getValue()}
              onChange={(val) => onRadioChange(val.target.value)}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel className={styles.height30px} value={true} control={<Radio/>}
                                label="Cumulatively"/>
              <FormControlLabel className={styles.height30px} value={false}
                                control={<Radio/>} label="Non-Cumulatively"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={styles.notifyDiv}>
          <div className={styles.exlcMark}>
            <div className={styles.exlcCircle}>!</div>
          </div>
          <div className={styles.warningText}>StratifyPro will display progress {formState.cumulative ? 'cumulatively' : 'non-cumulatively'} regardless of how the sheet is set up. Your sheet will not be affected.</div>
        </div>

        <DefineYourTarget padding={0} errors={errors} item={item}
                          clearProp={clearProp} title={'Define your Targets'}
                          setFormValues={setFormValues}/>

      </div>
      <CustomModal
        isVisible={warningModal.isVisible}
        title={warningModal.title}
        closeModal={closeWarningModal}
        children={warningModal.component}
        maxWidth={warningModal.maxWidth}
      />
    </div>
  )

}

export default ExcelIntegration;

