import * as Yup from 'yup';
import {TRACKING} from "../constants/strings";
import {mixed} from "yup";

export const editPerspectiveSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
});

export const updateProgressSchema = ({tracking}) => {
  return Yup.object().shape({
    actualValue: tracking === TRACKING.MILESTONE ? Yup.number() : Yup.number().required('This field is required'),
    completedMilestoneId: tracking === TRACKING.MILESTONE ? Yup.number().required('This field is required') : Yup.number(),
    msg: Yup.string(),
  });
};

export const integrationFormSchema = Yup.object().shape({
    name: Yup.string().required(),
    period: Yup.string().required(),
    importance: Yup.number().required(),
});

export const trainingFormSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
});

const isValidUrl = (value) => {
    try {
        new URL(value);
        return true;
    } catch {
        return false;
    }
};

export const addTrainingFormSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    trainingCategoryId: Yup.number().required(),
    authorId: Yup.number().required('Author is required'),
    file: Yup.mixed()
        .required('A file or URL is required')
        .test('fileOrUrl', 'The value must be a file or a valid URL', value => {
            if (typeof value === 'string') {
                return isValidUrl(value);
            }
            return value && ['application/pdf', 'video/mp4'].includes(value.type);
        })
        .test('fileSize', 'Video file size cannot exceed 75MB', value => {
            if (value && value.type === 'video/mp4') {
                return value.size <= 75242880; // 50 MB in bytes
            }
            return true;
        })
});
