import React from "react";
import {ReactComponent as Profile} from '../../../../../assets/icons/profile.svg';
import {ReactComponent as Performance} from '../../../../../assets/icons/performanceIcon.svg';
import {ReactComponent as GoogleSheets} from '../../../../../assets/icons/google-sheets.svg';
import {ReactComponent as Zapier} from '../../../../../assets/icons/external-sources/zapier.svg';
import {ReactComponent as MicrosoftExcel} from "../../../../../assets/icons/external-sources/microsoft-excel-icon.svg";
export const selectTrackingOptions = [
  {
    id: 1,
    title: 'CALCULATIONS',
    titleFontSize: 16,
    options: [
      {
        id: 7,
        title: 'Metric System',
        value: 'metric',
        icon: <Performance width={20} height={20} style={{marginRight: 8}}/>,
        subtitle: 'Choose any existing calculation from your library',
        disabled: process.env.REACT_APP_METRIC_DISABLED === 'true'
      },
    ]
  },
  {
    id: 2,
    title: 'INTEGRATIONS',
    titleFontSize: 16,
    options: [
      {
        id: 4,
        title: 'Zapier',
        value: 'zapier',
        icon: <Zapier width={20} height={20} style={{marginRight: 8}} />,
        subtitle: '',
      },
      {
        id: 5,
        title: 'Google Sheets',
        value: 'googleSheet',
        icon: <GoogleSheets width={20} height={20} style={{marginRight: 8}} />,
        subtitle: '',
        disabled: false
      },
      {
        id: 6,
        title: 'Microsoft Excel',
        value: 'microsoftExcel',
        icon: <MicrosoftExcel width={20} height={20} style={{marginRight: 8}} />,
        subtitle: '',
        disabled: false
      },
    ]
  },
  {
    id: 3,
    title: 'MANUAL',
    titleFontSize: 16,
    options: [
      {
        id: 1,
        title: 'Manually (no calculations)',
        value: 'manually',
        icon: <Profile width={20} height={20} style={{marginRight: 8}}/>,
        subtitle: 'Update progress manually',
        disabled: false
      },
    ]
  },
  // {
  //   id: 4,
  //   title: `CAN'T FIND WHAT YOU'RE LOOKING FOR?`,
  //   titleFontSize: 12,
  //   options: [
  //     {
  //       id: 6,
  //       title: 'Request a new integration',
  //       value: 'new_integration',
  //       icon: '',
  //       subtitle: '',
  //       disabled: true
  //     }
  //   ]
  // }
]
