import React, {useCallback, useEffect, useState} from 'react';
import styles from './TrackingComponent.module.scss'
import {ReactComponent as TrackingIcon} from '../../../assets/icons/toolsIcon.svg';
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrowDown.svg';
import GroupSelect from "../Inputs/GroupSelect/GroupSelect";
import {ROW_ITEM_TYPES, TRACKING, TRACKING_TYPES} from "../../../constants/strings";
import ExpectedProgress from "./ExpectedProgress";
import DefineYourTarget from "./DefineYourTargets";
import MilestoneComponent from "./MilestoneComponent/MilestoneComponent";
import SheetIntegration from "./SheetIntegration/SheetIntegration";
import ChecklistComponent from "./ChecklistComponent/ChecklistComponent";
import MetricsComponent from "./MetricsComponent/MetricsComponent";
import metricsService from "../../../services/MetricsService";
import JiraIntegration from "./JiraIntegration/JiraIntegration";
import ExcelIntegration from "./ExcelIntegration/ExcelIntegration";


export function TrackingComponent({
                                    openTracking = false,
                                    errors,
                                    selectTitle,
                                    selectOptions,
                                    setFieldValue,
                                    itemType,
                                    item,
                                    isInModal = false,
                                    formValues,
                                    setValues,
                                    metricId,
                                    validateForm
                                  }) {

  const [isCollapsed, setIsCollapsed] = useState(openTracking);
  const [selectedTrackingOption, setSelectedTrackingOption] = useState('');
  const checklistsErrors = errors && errors.checklists;
  const jiraErrors = errors.jiraConnectionId || errors.jiraLink || errors.jiraIssueId || errors.jiraProjectId;
  const [metric, setMetric] = useState(null);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  const getMetricById = async () => {
    try {
      const res = await metricsService.getMetric(metricId)
      setMetric(res)
    } catch (e) {
      console.log('err', {e});
    }
  }

  useEffect(() => {
    if(metricId){
      getMetricById()
    }
  }, [metricId])
  const getTracking = () => {
    if(item){
      switch (item?.type) {
        case ROW_ITEM_TYPES.ACTION:
          return item?.tracking;
        case ROW_ITEM_TYPES.KPI:
          if(metric && metric.id) {
            return 'metric';
          } else {
            return item?.tracking;
          }
        default:
          return 'manually'
      }
    } else if(metric && metric.id) {
      return 'metric';
    } else {
      return 'manually'
    }

  }

  useEffect(() => {
    selectOptions?.forEach(option => {
      let found = option.options.find(itemOption => itemOption.value === getTracking())
      if (found) {
        setSelectedTrackingOption(found)
        setFieldValue('tracking', found.value)
        if (found.value === TRACKING.JIRA || found.value === TRACKING.MICROSOFT_EXCEL) {
          setIsCollapsed(true);
        }
      }
    });
  }, [selectOptions, metric])

  const handleChange = (val) => {
    setSelectedTrackingOption(val.target.value)
    let keysToDelete;
    switch (val.target.value.value) {
      case TRACKING.MANUALLY || TRACKING.ZAPIER:
        keysToDelete = ['checklists', 'milestones', 'googleSheetConnectionId', 'microsoftConnectionId', 'jiraConnectionId', 'jiraProjectId', 'jiraIssueId', 'jiraLink', 'jiraSearch'];
        if (formValues && formValues.metricIds) {
          formValues.metricIds = [];
        }
        break;

      case TRACKING.CHECKLIST:
        keysToDelete = ['milestones', 'jiraConnectionId', 'jiraProjectId', 'jiraIssueId', 'jiraLink', 'jiraSearch'];
        break;

      case TRACKING.MILESTONE:
        keysToDelete = ['checklists', 'jiraConnectionId', 'jiraProjectId', 'jiraIssueId', 'jiraLink', 'jiraSearch'];
        break;

      case TRACKING.METRIC:
        keysToDelete = ['googleSheetConnectionId', 'microsoftConnectionId'];
        break;

      case TRACKING.JIRA:
          keysToDelete = ['milestones', 'checklists'];
        break;

      case TRACKING.GOOGLE_SHEETS:
        keysToDelete = ['microsoftConnectionId', 'resourceId', 'resourceName', 'dateIdentifier', 'progressIdentifier', 'targetIdentifier', 'cumulative'];
        break;

      case TRACKING.MICROSOFT_EXCEL:
        keysToDelete = ['googleSheetConnectionId', 'resourceId', 'resourceName', 'dateIdentifier', 'progressIdentifier', 'targetIdentifier', 'cumulative'];
        break;

      default:
        if(val.target.value.value !== TRACKING.METRIC && (formValues && formValues.metricIds)) {
          formValues.metricIds = [];
        }
    }
    keysToDelete?.forEach(key => {
      if (formValues && formValues[key] !== undefined) {
        delete formValues[key];
      }
    });
    setValues(formValues)
    setFieldValue('tracking', val.target.value.value)
  }

  const renderTrackingContent = () => {
    switch (selectedTrackingOption?.id) {
      case 1:
        return getContentByItemType();
      case 2:
        return getContentMilestone();
      case 3:
        return checklistContent();
      case 4:
        return jiraContent();
      case 5:
        return option5Content();
      case 6:
        return option6Content();
      case 7:
        return get7content();
      default:
        return;
    }
  }

  const get7content = () => {
    switch (itemType) {
      case ROW_ITEM_TYPES.ACTION:
        return
      case ROW_ITEM_TYPES.KPI:
        return (
          <MetricsComponent
            metric={metric}
            clearProp={selectedTrackingOption}
            setFieldValue={setFieldValue}
            item={item}
            errors={errors}
            setFormValues={setFormValues}
          />
        );
      default:
        return <div></div>;
    }
  }

  const onHandleFormField = useCallback((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      setFieldValue(key,value === '' ? null : value);
    });
  }, [setFieldValue]);

  const setFormValues = useCallback((values) => {
    onHandleFormField(values);
  }, [onHandleFormField]);

  const checklistContent = () => {
    switch (itemType) {
      case ROW_ITEM_TYPES.ACTION:
        return <ChecklistComponent item={item} items={item?.checklists} errors={checklistsErrors} setFormValues={setFormValues}/>
      default:
        return;
    }
  }

  const option5Content = () => {
    switch (itemType) {
      case ROW_ITEM_TYPES.KPI:
        return <SheetIntegration clearProp={selectedTrackingOption} item={item} errors={errors} setFormValues={setFormValues}/>
      case ROW_ITEM_TYPES.ACTION:
        return <ExpectedProgress item={item} clearProp={selectedTrackingOption} title={'Expected progress'}
                                 setFormValues={setFormValues}/>
      default:
        return;
    }
  }

  const option6Content = () => {
    switch (itemType) {
      case ROW_ITEM_TYPES.KPI:
        return <ExcelIntegration clearProp={selectedTrackingOption} item={item} errors={errors} setFormValues={setFormValues}/>
      default:
        return;
    }
  }

  const jiraContent = () => {
    switch (itemType) {
      case ROW_ITEM_TYPES.ACTION:
        return <JiraIntegration validateForm={validateForm} formValues={formValues} clearProp={selectedTrackingOption} item={item} errors={errors} setFormValues={setFormValues}/>
      case ROW_ITEM_TYPES.KPI:
        return <DefineYourTarget padding={20} errors={errors} item={item}
                                 clearProp={selectedTrackingOption} title={'Define your Targets'}
                                 setFormValues={setFormValues}/>
      default:
        return;
    }
  }

  const getContentByItemType = () => {
    switch (itemType) {
      case ROW_ITEM_TYPES.ACTION:
        return <ExpectedProgress item={item} clearProp={selectedTrackingOption} title={'Expected progress'}
                                 setFormValues={setFormValues}/>
      case ROW_ITEM_TYPES.KPI:
        return <DefineYourTarget padding={20} errors={errors} item={item}
                                 clearProp={selectedTrackingOption} title={'Define your Targets'}
                                 setFormValues={setFormValues}/>
      default:
        return;
    }
  }

  const getContentMilestone = () => {
    switch (itemType) {
      case ROW_ITEM_TYPES.ACTION:
        return <MilestoneComponent formValues={formValues} item={item} items={item?.milestones} errors={errors} setFormValues={setFormValues}/>
      default:
        return;
    }
  }

  return (
    <div className={`
        ${styles.container}
        ${!isInModal ? styles.notInModal : null}`} style={{height: isInModal ? 'auto' : isCollapsed ? 'auto' : '70px'}}>
      <div className={styles.mainView}>
        <div className={styles.titleView}>
          <TrackingIcon width={20} height={20}/>
          <p className={styles.titleText}>Tracking</p>
          <div>
            {checklistsErrors || jiraErrors ? <p className={styles.cardErrorMsg}>{'* Required'}</p> : null}
          </div>
          {
            !isCollapsed && selectedTrackingOption && <p className={styles.status}>{selectedTrackingOption.title}</p>
          }
        </div>
        {!isInModal &&
          <div className={styles.dropdownIcon} style={{transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'}}
               onClick={handleCollapse}>
            <ArrowDown/>
          </div>}
      </div>
      {
        (isCollapsed || isInModal) && (
          <div className={styles.collapseView}>
            {selectOptions && <GroupSelect disabled={Boolean(metric && metric.id)} handleChange={handleChange} title={selectTitle} selectOptions={selectOptions}
                                           value={selectedTrackingOption}/>}
            {renderTrackingContent()}
          </div>
        )
      }
    </div>
  );
}

export default TrackingComponent;
