import { takeLatest, call, select, put } from 'redux-saga/effects';
import { startAction, stopAction } from '../loading/actions';
import {
  LOGIN_REQUEST,
  FETCH_AUTHENTICATED_USER_REQUEST,
  LOGOUT_REQUEST,
  REGISTER_REQUEST,
  ON_GOOGLE_SIGN_UP,
  ON_LINKEDIN_SIGN_UP,
  RESET_PASSWORD_SAGA,
  SELECT_ORGANIZATION,
  CREATE_ORGANIZATION, SEND_TWO_FA_REQUEST_SAGA,
  SIGN_IN_MICROSOFT_SAGA, SIGN_IN_FROM_INVITATION

} from './actionTypes';
import {push, replace} from "connected-react-router";
import {
  fetchAuthenticatedUserSuccess,
  loginError,
  loginSuccess, logoutSuccess, registerError, registerSuccess, selectOrganizationSaga,
  setLoginError, setSidebarState, setTerminology,
  setToken, setTwoFAError,
  setUserJwtData
} from "./actions";
import {HOME, LOGIN, REDIRECT_TO_HOME, SETTINGS, WORKSPACE, WORKSPACE_TWO_FA} from "../../routesConstants";
import {HTTP_STATUS_CODES} from "../../constants/http";
import authService from "../../services/AuthService";
import {setLoader} from "../loader/actions";
import {setSnackbar} from "../snackbar/actions";
import {resetState} from "../scorecard/actions";
import organizationService from "../../services/OrganizationService";
import {createRouteWithParams} from "../../utils/url";
import {makeSelectUser} from "./selectors";

export function* authorize({ type, email, password }) {
  try {
    yield put(setLoader(true));
    yield put(startAction(type));
    const res = yield call(authService.login, { email, password });
    yield put(loginSuccess());
    yield put(setToken('token', res.token));
    const userOrganizations = yield call(organizationService.getUsersOrganizations);
    if (userOrganizations.length > 1) {
      yield put(push(WORKSPACE));
    } else {
      yield put(push(HOME));
    }
  } catch (error) {
    if (error && error?.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      yield put(setLoginError(error.response.data.message));
    }
    yield put(loginError());
  } finally {
    yield put(setLoader(false));
    yield put(stopAction(type));
  }
}

export function* signInMicrosoftSagaFn({ payload }) {
  try {
    const res = yield call(authService.microsoftLogin,{code: payload});
    yield put(loginSuccess());
    yield put(setToken('token', res.token));
    yield call(authService.setAuthToken, res.token)
    const userOrganizations = yield call(organizationService.getUsersOrganizations);
    if (userOrganizations.length > 1) {
      yield put(push(WORKSPACE));
    } else {
      yield put(push(HOME));
    }
  } catch (error) {
    console.log(error)
    if (error && error?.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      yield put(setLoginError(error.response.data.message));
    }
    yield put(loginError());
  } finally {
    yield put(setLoader(false));
  }
}

export function* onGoogleSignUpSagaFn({ payload }) {
  try {
    yield put(setLoader(true));
    const res = yield call(authService.loginGoogle, { accessToken:  payload.access_token});
    yield put(loginSuccess());
    yield put(setToken('token', res.token));
    yield put(push(HOME));
  } catch (error) {
    if (error && error?.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      yield put(setLoginError(error.response.data.message));
    }
    yield put(loginError());
  } finally {
    yield put(setLoader(false));
  }
}

export function* onLinkedInSignUpSagaFn(code) {
  try {
    yield put(setLoader(true));
    const res = yield call(authService.loginLinkedIn, { code:  code.payload});
    yield put(loginSuccess());
    yield put(setToken('token', res.token));
    yield put(push(HOME));
  } catch (error) {
    if (error && error?.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      yield put(setLoginError(error.response.data.message));
    }
    yield put(loginError());
  } finally {
    yield put(setLoader(false));
  }
}

export function* register({ type, password, name, email }) {
  yield put(setLoader(true));
  try {
    const data = {
      email,
      password,
      name
    }
    yield put(startAction(type));
    yield call(authService.register, data);
    yield put(registerSuccess(true));
  } catch (error) {
    if (error && error?.response?.status === HTTP_STATUS_CODES.SERVER_ERROR) {
      yield put(setLoginError(error.response.data.message));
    }
    yield put(registerError());
  } finally {
    yield put(setLoader(false));
    yield put(stopAction(type));
  }
}

export function* resetPasswordSagaFn({ payload }) {
  yield put(setLoader(true));
  try {
    yield call(authService.resetPassword, payload);
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: 'Password changed successfully',
    }))
    yield put(push(LOGIN));
  } catch (error) {
    if (error && error?.response?.status === HTTP_STATUS_CODES.SERVER_ERROR) {
      yield put(setLoginError(error.response.data.message));
    }
    yield put(registerError());
  } finally {
    yield put(setLoader(false));
  }
}

export function* fetchUser({ type }) {
  yield put(startAction(type));
  try {
    const sidebarStorage = localStorage.getItem('sidebar');
    const selectedTabSettingsStorage = sessionStorage.getItem('selectedTab');
    if(!selectedTabSettingsStorage) {
      sessionStorage.setItem('selectedTab', '1');
    }
    if (sidebarStorage !== null) {
      yield put(setSidebarState(sidebarStorage))
    } else {
      yield put(setSidebarState('show'))
    }
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
    yield put(setTerminology(user.framework));
    const token = yield call(authService.getAccessToken);
    if (token) {
      const userData = yield call(authService.decodeJwt, token);
      yield put(setUserJwtData(userData));
    }
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(stopAction(type));
  }
}

export function* logout() {
  try {
    yield call(authService.destroySession);
    yield put(logoutSuccess());
    yield put(resetState());
    yield put(push(LOGIN));
  } catch (error) {
    //
  }
}

export function* selectOrganizationSagaFn({organization}) {
  try {
    if (organization.organization.twoFAEnabled) {
      yield call(authService.selectOrganization, organization.id);
      yield put(push(WORKSPACE_TWO_FA, {organizationId: organization.id}));
    } else {
      const res = yield call(authService.selectOrganization, organization.id);
      yield put(setToken('token', res));
      const user = yield call(authService.fetchAuthenticatedUser);
      yield put(fetchAuthenticatedUserSuccess(user));
      yield put(setTerminology(user.framework));
      yield put(push(HOME));
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* signInFromInvitationSagaFn({payload}) {
  try {
    yield put(push(REDIRECT_TO_HOME));
    yield put(setToken('token', payload.token));
    yield call(authService.setAllTokens,payload);
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
    yield put(setTerminology(user.framework));
    yield put(push(HOME));
  } catch (error) {
    console.log({ error });
  }
}

export function* twoFASagaFn({payload}) {
  try {
    const res = yield call(authService.twoFARequest, payload);
    yield put(setToken('token', res));
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
    yield put(setTerminology(user.framework));
    yield put(push(HOME));
  } catch (error) {
    yield put(setTwoFAError(true));
    console.log({ error });
  }
}

export function* createOrganizationSagaFn({data}) {
  try {
    const res = yield call(organizationService.createOrganization, data);
    yield put(setToken('token', res));
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
    yield put(setTerminology(user.framework));
    yield put(push(HOME));
  } catch (error) {
    console.log({ error });
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, authorize);
  yield takeLatest(SIGN_IN_FROM_INVITATION, signInFromInvitationSagaFn);
  yield takeLatest(SIGN_IN_MICROSOFT_SAGA, signInMicrosoftSagaFn);
  yield takeLatest(RESET_PASSWORD_SAGA, resetPasswordSagaFn);
  yield takeLatest(ON_GOOGLE_SIGN_UP, onGoogleSignUpSagaFn);
  yield takeLatest(ON_LINKEDIN_SIGN_UP, onLinkedInSignUpSagaFn);
  yield takeLatest(REGISTER_REQUEST, register);
  yield takeLatest(FETCH_AUTHENTICATED_USER_REQUEST, fetchUser);
  yield takeLatest(LOGOUT_REQUEST, logout);
  yield takeLatest(SELECT_ORGANIZATION, selectOrganizationSagaFn);
  yield takeLatest(SEND_TWO_FA_REQUEST_SAGA, twoFASagaFn);
  yield takeLatest(CREATE_ORGANIZATION, createOrganizationSagaFn);
}
