import React, {useCallback} from 'react';
import styles from './AppBar.module.scss';
import {IconButton, Menu, styled} from "@mui/material";
import {ReactComponent as AddIcon} from '../../../src/assets/icons/plus-circle.svg';
import {ReactComponent as Search} from '../../../src/assets/icons/search.svg';
import {ReactComponent as Chat} from '../../../src/assets/icons/chat.svg';
import {ReactComponent as Question} from '../../../src/assets/icons/question.svg';
import {ReactComponent as Share} from '../../../src/assets/icons/share.svg';
import PopupComponent from "../Shared/Popup/PopupComponent";
import {addNewItemMenuConfig} from "./addNewItemMenuConfig";
import {useDispatch, useSelector} from "react-redux";
import {rightModalOpen, setActionModalFromNavbar, setItemAndParentForAction} from "../../store/helper/actions";
import CustomModal from "../Shared/Modals/CustomModal";
import {actionModalFromNavbarSelector} from "../../store/helper/selectors";
import AddGoalModalContentComponent
    from "../../containers/StrategyPlanPage/PerspectiveComponent/AddGoalModalContentComponent";
import scorecardService from "../../services/ScorecardService";
import DynamicForm from "../Shared/Form/DynamicForm";
import {createScorecardFormConfig} from "../../containers/ScorecardListPage/createScorecardFormConfig";
import {createGroupSchema, createScorecardSchema} from "../../validation/scorecard";
import {createGroupSaga, manageScorecardSaga} from "../../store/scorecard/actions";
import {groupFormConfig} from "../../containers/ScorecardListPage/groupFormConfig";
import {makeSelectUser, terminologySelector} from "../../store/auth/selectors";
import {PROFILE, SETTINGS, STRATIFYAI, VNC_ACADEMY, WORKSPACE, WORKSPACE_CREATE} from "../../routesConstants";
import {useHistory} from "react-router-dom";
import {rightSidebarSubmitFormSaga} from "../../store/strategyPlan/actions";
import {helperService} from "../../services/HelperService";
import {ACTIONS, Role, ROW_ITEM_TYPES, SUBSCRIPTION_STATUS} from "../../constants/strings";
import profileImage from "../../assets/images/user.png";
import {logout} from "../../store/auth/actions";
import GoalsLibraryModal from "../GoalsLibrary/GoalsLibraryModal";
import {goalsLibraryModalSelector} from "../../store/goals/selectors";
import {setGoalsLibraryModal} from "../../store/goals/actions";
import {PAGES} from "../../constants/pages";

function AppBar({isDashboard = false, completedOnBoarding = true, supportingText, title}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(makeSelectUser());
    const goalsLibraryModal = useSelector(goalsLibraryModalSelector());
    const terminology = useSelector(terminologySelector());

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const modalInitialState = {
        isVisible: false,
        title: '',
        maxWidth: 'md',
        component: null,
    };

    const StyledMenu = styled(Menu)`
      & .MuiPaper-root {
        border-radius: 8px;
      }

      & .MuiList-root {
        margin: 0;
        padding: 0;
      }
    `;
    const RoundIconButton = ({icon, onClick}) => {
        return (
            <IconButton className={styles.iconButton} onClick={onClick}>
                {icon}
            </IconButton>
        );
    }
    const getPlanId = () => {
        const pathname = history.location.pathname;
        if (pathname.includes('strategy-plan-view')) {
            const pathParts = pathname.split('/');
            const index = pathParts.indexOf('strategy-plan-view');
            if (index !== -1 && index < pathParts.length - 1) {
                const number = pathParts[index + 1];
                return parseInt(number, 10);
            }
        } else {
            return null;
        }
    }

    const GettingStartedButton = ({onClick}) => {
        return (
            <IconButton className={styles.gettingStartedButton} onClick={onClick}>
                <div className={styles.gettingStartedButtonIcon}>
                    <Question className={styles.questionIconOnBoarding}/>
                </div>
                <p className={styles.gettingStartedText}>Getting Started</p>
            </IconButton>
        );
    }

    const closeSidebar = useCallback(() => {
        dispatch(rightModalOpen({
            isVisible: false,
            component: null,
            page: null,
        }));
    }, [dispatch]);

    const onAddGoal = () => {
        dispatch(rightModalOpen({
            isVisible: true,
            component: (
                <AddGoalModalContentComponent
                    onFormSubmit={(values) => {
                        dispatch(rightSidebarSubmitFormSaga(
                            values,
                            ACTIONS.ADD,
                            ROW_ITEM_TYPES.GOAL,
                            null,
                            getPlanId(),
                            true
                        ));
                    }}
                    planId={getPlanId()}
                    parentType={ROW_ITEM_TYPES.PERSPECTIVE}
                    onCancel={closeSidebar}
                    canContributesToEditParent={false}
                />
            ),
            page: getPlanId() ? PAGES.STRATEGY_PLAN : null,
        }));
    };

    const onCreatePlan = async () => {
        let planTemplates;
        try {
            planTemplates = await scorecardService.getTemplates();
        } catch (error) {
            console.error({error});
        }

        const planTemplateOptions = planTemplates.map((planTemplate, index) => {
            return {
                id: planTemplate.id,
                value: planTemplate.id,
                title: index === 0 ? 'StratifyPro Framework' : planTemplate.name,
            };
        });

        dispatch(setActionModalFromNavbar({
            isVisible: true,
            title: `Add New ${terminology.plan}`,
            component: (
                <DynamicForm
                    config={createScorecardFormConfig({
                        onCancel: closeActionFromNavbarModal,
                        planTemplateOptions,
                        terminology,
                    })}
                    validationSchema={createScorecardSchema}
                    handleOnSubmit={(values) => manageScorecard(values, true)}
                />
            ),
        }))
    }

    const onCreateGroup = async () => {
        let users;
        try {
            users = await helperService.getUsersByOrganization();
        } catch (error) {
            console.error({error});
        }

        const options = users.map((user) => {
            return {
                id: user.id,
                value: user.id,
                title: user.name,
                subtitle: user.email,
                image: user.photo,
            };
        });

        dispatch(setActionModalFromNavbar({
            isVisible: true,
            title: 'Add Group',
            maxWidth: 'sm',
            component: (
                <DynamicForm
                    config={groupFormConfig({user, onCancel: closeActionFromNavbarModal, options})}
                    validationSchema={createGroupSchema}
                    handleOnSubmit={(values) => createGroup(values)}
                />
            ),
        }))
    }

    const createGroup = useCallback((values) => {
        dispatch(createGroupSaga(values));
    }, [dispatch]);

    const manageScorecard = useCallback((values, create, scorecardId) => {
        dispatch(manageScorecardSaga(values, create, scorecardId));
    }, [dispatch]);

    const closeActionFromNavbarModal = () => {
        dispatch(setActionModalFromNavbar(modalInitialState));
        dispatch(setItemAndParentForAction({parent: null, item: null}));
    }

    const onInviteTeammates = useCallback(() => {
        sessionStorage.setItem('selectedTab', '1');
        history.push(SETTINGS);
    }, []);

    const modal = useSelector(actionModalFromNavbarSelector());

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const onLogout = () => {
        dispatch(logout());
    }

    const onGoToProfile = () => {
        setAnchorEl(null);
        history.push(PROFILE);
    }

    const onGoToSettings = () => {
        setAnchorEl(null);
        history.push(SETTINGS);
    }

    const onChooseWorkspace = () => {
        setAnchorEl(null);
        history.push(WORKSPACE);
    }

    const closeGoalsLibraryModal = () => {
        dispatch(setGoalsLibraryModal({
            isVisible: false,
            goalId: null,
            scorecardId: null,
            page: null,
        }));
    };

    const onCreateWorkspace = () => {
        history.push(WORKSPACE_CREATE);
    }
    const onGeneratePlan = () => {
        history.push(STRATIFYAI);
    }

    return (
        <div className={styles.innerWrapper}>
            <div className={styles.breadcrumbWrapper}>
                <div>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.subtitle}>{supportingText}</p>
                </div>
            </div>
            {completedOnBoarding ?
                <div className={styles.roundIconsWrapper}>
                    {(user.organization.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBED || user.organization.subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL) && (
                        <PopupComponent
                            config={addNewItemMenuConfig({
                                onAddGoal,
                                onCreatePlan,
                                onCreateGroup,
                                onInviteTeammates,
                                onGeneratePlan,
                                terminology,
                            })}
                        />
                    )}
                    {isDashboard && <RoundIconButton icon={<Share/>}/>}
                    <RoundIconButton icon={<Chat/>}/>
                    <RoundIconButton icon={<Question/>} onClick={() => history.push(VNC_ACADEMY)}/>
                    <RoundIconButton icon={<Search/>}/>
                    <div>
                        <div onClick={handleClick} className={styles.iconButtonAvatar}>
                            <img src={user.photo ? user.photo : profileImage} className={styles.profileImg}
                                 alt={'user'}/>
                        </div>
                        <StyledMenu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            disableScrollLock={true}
                        >
                            <div className={styles.popUpWrapper}>
                                <p className={styles.titleText}>Settings</p>
                                <div className={styles.btnWrapper}>
                                    {
                                        user.role === Role.ADMIN && (
                                            <button onClick={onGoToSettings} className={styles.popUpBtn}>
                                                <p className={styles.popUpBtnText}>Admin</p>
                                            </button>
                                        )
                                    }
                                    <button onClick={onGoToProfile} className={styles.popUpBtn}>
                                        <p className={styles.popUpBtnText}>My Profile</p>
                                    </button>
                                </div>
                                <p className={styles.titleText}>workspace: {user?.organization?.name}</p>
                                <div className={styles.btnWrapperSecond}>
                                    <button className={styles.popUpBtn} onClick={onCreateWorkspace}>
                                        <p className={styles.popUpBtnText}>Create New Workspace</p>
                                    </button>
                                    <button onClick={onChooseWorkspace} className={styles.popUpBtn}>
                                        <p className={styles.popUpBtnText}>Switch Workspace</p>
                                    </button>
                                    <button onClick={onLogout} className={styles.popUpBtnLogout}>
                                        <p className={styles.popUpBtnText}>Logout</p>
                                    </button>
                                </div>
                            </div>
                        </StyledMenu>
                    </div>
                </div>
                :
                <div className={styles.roundIconsWrapper}>
                    <RoundIconButton icon={<AddIcon width={24} height={24}/>}/>
                    {isDashboard && <RoundIconButton icon={<Share/>}/>}
                    <RoundIconButton icon={<Search/>}/>
                    <RoundIconButton icon={<Chat/>}/>
                    <GettingStartedButton/>
                </div>
            }
            <CustomModal isVisible={modal.isVisible} title={modal.title} closeModal={closeActionFromNavbarModal}
                         children={modal.component}/>
            <GoalsLibraryModal
              title={`My ${terminology.goal}s Library`}
              closeModal={closeGoalsLibraryModal}
              isVisible={goalsLibraryModal.isVisible}
              scorecardId={goalsLibraryModal.scorecardId}
              goalId={goalsLibraryModal.goalId}
              perspectiveId={goalsLibraryModal.perspectiveId}
              page={goalsLibraryModal.page}
            />
        </div>
    );
}


export default AppBar;
