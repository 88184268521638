import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import styles from "../../style.module.scss";
import Loader from "../Loader";
import SideBar from "../../components/SideBar";
import AppBar from "../../components/AppBar";
import {useSelector} from "react-redux";
import {sidebarStateSelector} from "../../store/auth/selectors";
import RightModal from "../../components/RightModal";
import {HOME, LOGIN, PRICING, PROFILE, SETTINGS, WORKSPACE, WORKSPACE_CREATE} from "../../routesConstants";
import Settings from "../../containers/Settings/Loadable";
import CheckoutRedirectPage from "../../containers/LoginPage/CheckoutRedirect/Loadable";
import {Role, SUBSCRIPTION_STATUS} from "../../constants/strings";
import ProfileSettingsPage from "../ProfileSettingsPage/Loadable";
import {PAGES} from "../../constants/pages";
import CreateWorkspacePage from "../WorkspacePage/CreateWorkspacePage";
import WorkspacePage from "../WorkspacePage/Loadable";
import PricingPage from "../PricingPage/Loadable";

export function PrivateRoute({
                                 component: Component,
                                 user,
                                 title,
                                 isWorkspace = false,
                                 ...rest
                             }) {
    const sidebarState = useSelector(sidebarStateSelector());

    const routingSystem = () => {
        return (
            <Switch>
                <Route user={user} exact path={HOME} component={CheckoutRedirectPage} />
                <Route user={user} exact path={PROFILE} component={ProfileSettingsPage} title={PAGES.PROFILE_SETTINGS} />
                <Route user={user} exact path={WORKSPACE_CREATE} component={CreateWorkspacePage} isWorkspace={true} />
                <Route user={user} exact path={WORKSPACE} component={WorkspacePage} isWorkspace={true} />
                <Route path="*" component={CheckoutRedirectPage} />
            </Switch>
        )
    }

    const routingSystemAdminSubExpired = () => {
        return (
            <Switch>
                <Route user={user} exact path={SETTINGS} component={Settings} />
                <Route user={user} exact path={PROFILE} component={ProfileSettingsPage} title={PAGES.PROFILE_SETTINGS} />
                <Route user={user} exact path={PRICING} component={PricingPage} title={PAGES.PRICING}/>
                <Route user={user} exact path={WORKSPACE_CREATE} component={CreateWorkspacePage} isWorkspace={true} />
                <Route user={user} exact path={WORKSPACE} component={WorkspacePage} isWorkspace={true} />
                <Route path="*" component={Settings} />
            </Switch>
        )
    }
    const checkPermissions = (props) => {
        if (user) {
            if (user.organization.stripe) {
                if (user.role === Role.ADMIN) {
                    if (user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBED && user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.TRIAL) {
                        return user.organization.subscriptionStatus === SUBSCRIPTION_STATUS.NONE ? routingSystem() : routingSystemAdminSubExpired();
                    }
                } else {
                    if (user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBED && user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.TRIAL) {
                        return routingSystem()
                    }
                }
                return <Component {...props} />
            } else {
                return <Component {...props} />
            }
        } else {
            return <Redirect to={LOGIN}/>;
        }
    }

    return (
        <div className={styles.appDiv}>
            <Loader/>
            {user && !isWorkspace && user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.NONE && (
                <div style={{width: sidebarState === 'hidden' ? '96px' : '260px'}} className={styles.sideBarDiv}>
                    <RightModal/>
                    <SideBar
                        user={user}
                    />
                </div>
            )}
            {user && !isWorkspace && user.organization.stripe === false && (
                <div style={{width: sidebarState === 'hidden' ? '96px' : '260px'}} className={styles.sideBarDiv}>
                    <RightModal/>
                    <SideBar
                        user={user}
                    />
                </div>
            )}
            <div className={styles.container}>
                {user && !isWorkspace && user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.NONE && (
                    <div
                        className={styles.appBarDiv}>
                        <AppBar title={title}/>
                    </div>
                )}
                {
                    user && user.organization.stripe === false && (
                        <div
                            className={styles.appBarDiv}>
                            <AppBar title={title}/>
                        </div>
                    )
                }
                <div
                    className={styles.contentDiv}>
                    <Route
                        {...rest}
                        render={(props) => checkPermissions(props)}
                    />
                </div>
            </div>
        </div>
    );
}

export default PrivateRoute;
