import {capitalizeFirstLetter} from "../utils/format";

export const HORIZONTAL_TAB_VALUES = {
    progress: 'Progress',
    performance: 'Performance',
    budget: 'Budget',
    tasks: 'Tasks'
}

export const FRAMEWORKS_ENUM = {
    STRATIFY_PRO_STRATEGY: 'stratify-pro-strategy',
    OKR: 'okr',
    BALANCED_SCORECARD: 'balanced-scorecard',
    HOSHIN_KANRI: 'hoshin-kanri',
    V2MOM: 'v2mom',
}

export const SIDE_BAR_ITEMS = ({terminology}) => {
    return {
        HOME: 'HOME',
        SCORECARDS: `Groups & ${terminology.plan}s`,
        DASHBOARDS: 'Dashboards',
        INTEGRATIONS: 'Integrations',
        CONNECT_METRICS: 'Connect Metrics',
        REPORTS: 'Reports',
        RELEASE_NOTES: 'Release Notes',
        VNC_ACADEMY: 'Training Center',
        SETTINGS: 'Settings',
        ALIGNMENT: 'Alignments',
    };
};

export const PROFILE_TABS = [
    {
        id: 1,
        title: 'Profile',
    },
    {
        id: 2,
        title: 'Notifications',
    },
    {
        id: 3,
        title: 'Change Password',
    }
]


export const SETTINGS_TABS = (isDisabled) => {
    return (
        [
            {
                id: 1,
                title: 'Users',
            },
            {
                id: 2,
                title: 'Billing',
            },
            {
                id: 3,
                title: 'Organization',
                disabled: isDisabled
            },
            {
                id: 5,
                title: 'Frameworks',
                disabled: isDisabled
            },
            {
                id: 6,
                title: 'Guiding Principles',
                disabled: isDisabled
            },
            {
                id: 7,
                title: 'Themes',
                disabled: isDisabled
            }
        ]
    )
}

export const STRATEGY_PLAN_TABS = ({terminology}) =>{
    return [
        {
            id: 1,
            title: terminology.plan,
        },
        {
            id: 2,
            title: 'Timeline View',
        },
        {
            id: 3,
            title: 'Alignment Map',
        },
    ];
};

export const ALIGNMENT_MAP_TABS = ({terminology}) => {
    return [
        {
            id: 1,
            title: `${terminology.plan}s`,
        },
        {
            id: 2,
            title: `${terminology.goal}s`,
        }
    ];
};

export const DASHBOARDS_TABS = [
    {
        id: 1,
        title: 'All Charts & Graphs',
    }
];

export const METRICS_TABS = [
    {
        id: 1,
        title: 'Files',
    },
    {
        id: 2,
        title: 'Connect external sources',
    },
    {
        id: 3,
        title: 'Create Drag and Drop Metrics',
        disabled: true,
    }
];

export const AI_OPTION_TABS = [
    {
        id: 1,
        title: 'Generate',
    }
];

export const METRICS_ADD_TABS = [
    {
        id: 1,
        title: 'Details',
    },
    {
        id: 2,
        title: 'Data Sourcing',
        disabled: true
    }
];



export const Role = {
    ADMIN: 'admin',
    OWNER: 'owner',
    LEADER: 'leader',
    CONTRIBUTOR: 'contributor',
    VIEWER: 'viewer',
};

export const SUBSCRIPTION_STATUS = {
    NONE: 'none',
    SUBSCRIBED: 'subscribed',
    EXPIRED: 'expired',
    TRIAL: 'trial',
    CANCELED: 'canceled',
    TRIALING: 'trialing',
};

export const GRAPH_TYPES = {
    HEALTH_OF_YOUR_PLAN: 'health-of-your-plan',
    CURRENT_STATE_OF_KPI: 'current-state-of-kpi',
    COMPARE_MULTIPLE_KPIS: 'compare-multiple-kpis',
    COMPARE_KPIS_DIFFERENT_UNIT: 'compare-kpis-with-different-measurement-units',
    RISK_DISTRIBUTION_SCATTER_PLOT: 'risk-distribution-scatter-plot',
    COMPARE_MULTIPLE_KPIS_BY_OWNER: 'compare-multiple-kpis-by-owner',
    COMPARE_MULTIPLE_GOALS: 'compare-multiple-goals',
    PERFORMANCE_OF_KPIS: 'performance-of-kpis',
    SINGLE_KPI_PERFORMANCE: 'single-kpi-performance',
    KPI_TABLE: 'kpi-table',
    INITIATIVE_TABLE: 'initiative-table',
    MULTIPLE_PLANS_SNAPSHOT: 'multiple-plans-snapshot',
    GROUPS_TABLE: 'group-table',
    GOALS_TABLE: 'goal-table',
};

export const CHART_TYPES = {
    HEALTH_OF_YOUR_PLAN: 'healthOfYourPlan',
    RISK_DISTRIBUTION: 'riskDistribution',
    COMPARE_MULTIPLE_KPIS: 'compareMultipleKpis',
    SINGLE_KPI_PERFORMANCE_WIDGET: 'singleKpiPerformanceWidget',
    COMPARE_KPIS_DIFFERENT_UNIT: 'compareKpisDifferentUnit',
    COMPARE_KPIS_DIFFERENT_OWNER: 'compareKpisDifferentOwner',
    CURRENT_KPI_STATE: 'currentKpiState',
    COMPARE_MULTIPLE_GOALS: 'compareMultipleGoals',
    PERFORMANCE_OF_KPIS: 'performanceOfKpis',
    KPI_TABLE: 'kpiTable',
    INITIATIVE_TABLE: 'initiativesTable',
    MULTIPLE_PLANS_SNAPSHOT: 'multiplePlansSnapshot',
    GROUPS_TABLE: 'groupTable',
    GOALS_TABLE: 'goalTable',
};

export const ROLES = [
    {
        id: 1,
        label: capitalizeFirstLetter(Role.ADMIN),
        title: capitalizeFirstLetter(Role.ADMIN),
        value: Role.ADMIN,
    },
    {
        id: 2,
        label: capitalizeFirstLetter(Role.LEADER),
        title: capitalizeFirstLetter(Role.LEADER),
        value: Role.LEADER,
    },
    {
        id:3,
        label: capitalizeFirstLetter(Role.OWNER),
        title: capitalizeFirstLetter(Role.OWNER),
        value: Role.OWNER,
    },
    {
        id:4,
        label: capitalizeFirstLetter(Role.CONTRIBUTOR),
        title: capitalizeFirstLetter(Role.CONTRIBUTOR),
        value: Role.CONTRIBUTOR,
    },
    // {
    //     id:5,
    //     label: capitalizeFirstLetter(Role.VIEWER),
    //     value: Role.VIEWER,
    // }
];

export const ItemHealth = {
    AT_RISK: 'At Risk',
    LATE: 'Late',
    ON_TRACK: 'On Track',
    ACHIEVED: 'Achieved',
    EXCEEDED: 'Exceeded',
    NOT_STARTED: 'Not Started',
};

export const UnitsContants = {
    DOLLAR: 'dollar',
    POUND: 'pound',
    PERCENTAGE: 'percentage',
    SECONDS: 'seconds',
    MINUTES: 'minutes',
    HOURS: 'hours',
    DAYS: 'days',
    WEEKS: 'weeks',
    MONTHS: 'months',
    YEARS: 'years',
    CUSTOMERS: 'customers',
    USERS: 'users',
    NPS_SCORE: 'nps-score',
    NUMBER: 'number',
    NO_UNIT: 'no-unit',
};

export const ItemStatus = {
    TODO: 'toDo',
    ON_HOLD: 'onHold',
    COMPLETE: 'complete',
    IN_PROGRESS: 'inProgress',
    NOT_DOING: 'notDoing',
};

export const TASK_TYPES = {
    PERSPECTIVE: 'perspective',
    GOAL: 'goal',
    GOAL_NESTED: 'goal-nested',
    GOAL_SHARED: 'goal-shared',
    GOAL_CONTRIBUTING: 'goal-contributing',
    KPI: 'kpi',
    PROJECT: 'project',
    ACTION: 'action',
    PLAN: 'plan',
    GROUP: 'group',
    OWNER: 'owner',
}

export const DASHBOARD_URI = {
    UpcomingGoals: 'upcoming-goals',
    RecentUpdates: 'recent-updates'
}

export const ROW_ITEM_TYPES = {
    GOAL: 'Goal',
    KPI: 'Kpi',
    PROJECT: 'Project',
    ACTION: 'Action',
    PERSPECTIVE: 'Perspective',
    PLAN: 'Plan',
    VIEWPOINT: 'Viewpoint',

    HEALTH: 'Health',
    TYPE: 'Type',
    ALL: 'all',
    GROUP: 'Group',
    OWNER: 'Owner'
};

export const PARAM_ITEM_TYPES = {
    GOAL: 'goal',
    KPI: 'kpi',
    PROJECT: 'project',
    ACTION: 'action',
    PLAN: 'plan',
};

export const ITEM_ROW_LINKED_TYPES = {
    SHARE: 'share',
    CONTRIBUTOR: 'contributor',
};

export const MOVE_ITEM_SELECT_TYPES = {
    PLAN: 'Plan',
    PROJECT: 'Project',
    GOAL: 'Goal',
};

export const DETAILS_TYPE = {
    OWNER: 'owner',
    OWNERS: 'owners',
    TIME_FRAME: 'time-frame',
    DESCRIPTION: 'description',
    UPDATE_SCHEDULE: 'update-schedule',
};

export const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    SHARE: 'share',
    MOVE: 'move',
    DELETE: 'delete',
    RE_EVALUATE: 're-evaluate',
    CLOSE_RISK: 'close-risk',
    PLAN_SETTINGS: 'plan-settings',
    CONNECT_METRICS_TO_KPI: 'connect-metrics-to-kpi',
    OPEN_HUB: 'open-hub',
    COPY_UPDATE_LINK: 'copy-update-link',
    VIEW_ALIGNMENT: 'view-alignment',
    DUPLICATE: 'duplicate',
    VIEW_PLAN: 'view-plan',
    PERSPECTIVE_DASHBOARD: 'perspective-dashboard',
    DELETE_FROM_PLAN: 'delete-from-plan',
    EXPORT_TO_PDF: 'export-to-pdf',
    EXPORT_TO_CSV: 'export-to-csv',
    MAKE_PRIVATE: 'make-private',
    MAKE_SHARED: 'make-shared',
    DISCONNECT: 'disconnect',
    VIEW_ITEM: 'view-item',
    SHOW_TODAY: 'show-today',
    SHOW_WEEKS: 'show-weeks',
    SHOW_MONTHS: 'show-months',
    SHOW_QUARTERS: 'show-quarters',
};

export const WEIGHT_TYPE = {
    ALL: 'all',
    KPI: 'kpi',
    ACTION_AND_PROJECT: 'action_and_project',
    GOALS: 'goals',
    CUSTOM: 'custom',
    SCALE: 'scale',
};

export const TRACKING_TYPE = {
    BETWEEN: 'between',
    EXCEED: 'exceed',
    BELOW: 'below',
    ACHIEVED: 'achieved',
    TYPE: 'trackingType',
};

export const TRACKING_PERIOD_TYPE = {
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
    QUARTERLY: 'quarterly',
    ANNUALLY: 'annually'
};

export const DASHBOARD_TABLE_ACTION = {
    DELETE: 'delete',
    SETTINGS: 'settings',
    DUPLICATE: 'duplicate',
};

export const PeriodDays =  {
    EveryMonday: 'everyMonday',
    EveryTuesday: 'everyTuesday',
    EveryWednesday: 'everyWednesday',
    EveryThursday: 'everyThursday',
    EveryFriday: 'everyFriday',
    EverySaturday: 'everySaturday',
    EverySunday: 'everySunday',
    FirstDayOfMonth: 'firstDayOfMonth',
    FirstMondayOfMonth: 'firstMondayOfMonth',
    LastDayOfMonth: 'lastDayOfMonth',
    LastFridayOfMonth: 'lastFridayOfMonth',
    FirstDayOfQuarterly: 'firstDayOfQuarterly',
    FirstMondayOfQuarterly: 'firstMondayOfQuarterly',
    LastDayOfQuarterly: 'lastDayOfQuarterly',
    LastFridayOfQuarterly: 'lastFridayOfQuarterly',
    FirstDayOfYear: 'FirstDayOfYear',
    FirstMondayOfYear: 'firstMondayOfYear',
    LastDayOfYear: 'lastDayOfYear',
    LastFridayOfYear: 'lastFridayOfYear',
}

export const ENTITY_SHARE_TYPES = {
    SCORECARD: 'scorecard',
    DASHBOARD: 'dashboard',
    REPORT: 'report',
};

export const SHARING_WITH_ENTITY_TYPES = {
    GROUP: 'Group',
    USER: 'User',
};

export const OWNER = 'Owner'

export const ITEM_HEALTH_STATUS = {
    AT_RISK: 'atRisk',
    ON_TRACK: 'onTrack',
    LATE: 'late',
    NOT_STARTED: 'notStarted',
};

export const GOALS_HEALTH = {
    AT_RISK: 'At Risk',
    LATE: 'Late',
    ON_TRACK: 'On Track',
    EXCEEDED: 'Exceeded',
    ACHIEVED: 'Achieved',
};

export const DASHBOARD_PERSPECTIVE_HEALTH = {
    AT_RISK: 'atRisk',
    LATE: 'late',
    ON_TRACK: 'onTrack',
    EXCEEDED: 'exceeded',
    ACHIEVED: 'achieved',
    NOT_TRACKED: 'notTracked',
    NOT_STARTED: 'notStarted',
};

export const DASHBOARD_PERSPECTIVE_HEALTH_CONVERTED = {
    AT_RISK: 'At risk',
    LATE: 'Late',
    ON_TRACK: 'On track',
    EXCEEDED: 'Exceeded',
    ACHIEVED: 'Achieved',
    NOT_TRACKED: 'Not tracked',
    NOT_STARTED: 'Not started',
};

export const TRACKING = {
    MANUALLY: 'manually',
    CHECKLIST: 'checklist',
    MILESTONE: 'milestone',
    GOOGLE_SHEETS: 'googleSheet',
    JIRA: 'jira',
    METRIC: 'metric',
    MICROSOFT_EXCEL: 'microsoftExcel',
    ZAPIER: 'zapier',
};

export const TRACKING_TYPES = {
    MANUAL: 'Manual',
    KPI: 'Kpi',
};

export const HUB_ENTITY_ROUTE_TYPE = {
    GOAL: 'goal',
    KPI: 'kpi',
    PROJECT: 'project',
    ACTION: 'action',
};

export const ITEM_STATUS = {
    ALL: 'all',
    NOT_TRACKED: 'not tracked',
    NOT_STARTED: 'not started',
    AT_RISK: 'at risk',
    LATE: 'late',
    ON_TRACK: 'on track',
    ACHIEVED: 'achieved',
    EXCEEDED: 'exceeded',
};

export const REORDER = {
    START: 'start',
    END: 'end',
    SAVE: 'save',
};

export const WEB_URL = {
    WEBINARS: 'https://www.stratifypro.com/stratifypro-webinars',
    WORKSHOPS: 'https://www.stratifypro.com/talk-to-an-expert',
    TRAINING_CENTER: 'https://www.stratifypro.com/training-center'
}

export const INTEGRATIONS_TABS = [
    {
        id: 1,
        title: 'Metrics',
    },
    {
        id: 2,
        title: 'Collaborate',
    },
    {
        id: 3,
        title: 'Calender',
        disabled: true,
    },
];

export const MANAGE_CONNECTIONS_TABS = [
    {
        id: 1,
        title: 'All connections',
    },
    {
        id: 2,
        title: 'Private',
    },
    {
        id: 3,
        title: 'Public',
    },
];

export const ITEM_UPDATE_TYPES = {
    CHECKLIST: 'checklist',
    CHILD: 'child',
    COMMENT: 'comment',
    CREATED: 'created',
    DATE: 'date',
    MILESTONE: 'milestone',
    MODIFIED: 'modified',
    TRACKING: 'tracking',
    VALUE: 'value',
    WEIGHT: 'weight',
    METRIC: 'metric',
};

export const REPORT_ELEMENT_TYPES = {
    TEXT: 'text',
    IMAGE: 'image',
    CHART: 'chart',
    TABLE: 'table',
};

export const REPORT_ICON_TYPES = {
    SIDEBAR: 'sidebar',
    SNAPSHOT: 'snapshot',
    COMMENTS: 'comments',
    SHARE: 'share',
};

export const DASHBOARD_WIDGET_TYPES = {
    TABLE: 'table',
    CHART: 'chart',
    SNAPSHOT: 'snapshot',
    ALL: 'all',
};

export const DATE_RANGE_TABS = {
    RELATIVE: 'Relative',
    HORIZONS: 'Horizons',
    CUSTOM: 'Custom',
};

export const DATE_RANGE_FIELDS = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
};

export const CONNECTION_TYPES = {
    GOOGLE_SHEET: 'google-sheet',
    GOOGLE_SHEETS: 'google-sheets',
    JIRA: 'jira',
    MICROSOFT_EXCEL: 'microsoft-excel',
    ZAPIER: 'zapier'
};

export const RENDER_REPORT_TYPES = {
    REPORT: 'report',
    SNAPSHOT: 'snapshot',
};

export const ADD_ACTION_TYPE = {
    STRATEGY_PLAN: 'strategyPlan',
    HOME_PAGE_GOAL: 'homePageGoal',
    HOME_PAGE_PROJECTS_ACTIONS_KPIS: 'homePageProjectsActionsKpis',
    UPCOMING_GOALS: 'upcoming-goals',
    ACTIONS_PROJECTS: 'actions-and-projects',
};

export const TIMELINE_TIME_RANGE_TYPES = {
    WEEKS: 'weeks',
    MONTHS: 'months',
    QUARTERS: 'quarters',
};

export const KANBAN_BOARD_COMPONENT_TYPES = {
    TASK: 'task',
    COLUMN: 'column',
};

export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
