import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  FILTER_GROUPS: 'groups/filter/:entity/:entityId/:term',
  ADD_UPDATE_TO_GROUP: 'groups/make-update',
  ALL_UPDATES: 'home/all-updates/:groupId',
  GROUPS_ONLY: 'groups',
};

class GroupService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  filterGroups = (entity, entityId, term) => {
    return this.httpService.request({
      url: ROUTES.FILTER_GROUPS
        .replace(':entity', entity)
        .replace(':entityId', entityId)
        .replace(':term', term || ''),
      method: HTTP_METHODS.GET,
    });
  };

  addGroupUpdate = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_UPDATE_TO_GROUP,
      method: HTTP_METHODS.POST,
      data
    });
  };

  allGroupUpdates = (id) => {
    return this.httpService.request({
      url: ROUTES.ALL_UPDATES.replace(':groupId', id ? id : ''),
      method: HTTP_METHODS.GET
    });
  };
  getOnlyGroups = (id) => {
    return this.httpService.request({
      url: ROUTES.GROUPS_ONLY,
      method: HTTP_METHODS.GET
    });
  };
}

const groupService = new GroupService(httpService);

export default groupService;
